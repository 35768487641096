@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

:root {
  --primary-color: rgb(0, 72, 168);
  --primary-color-triplet: 0, 72, 168;

  --secondary-color: rgb(11, 30, 81);
  --secondary-color-triplet: 11, 30, 81;

  --tertiary-color: rgb(66, 133, 244);
  --tertiary-color-triplet: 66, 133, 244;

  --gold-color: rgb(251, 229, 53);
  --gold-color-triplet: 251, 229, 53;

  --white-color: rgb(255, 255, 255);
  --white-color-triplet: 255, 255, 255;

  --black-color: rgb(0, 0, 0);
  --black-color-triplet: 0, 0, 0;

  --avatar-online-color: rgb(0, 255, 0);
    --avatar-offline-color: rgb(255, 0, 0);
}

html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  /* Preserve scrolling behavior */
  overflow-y: auto;
}

button {
  font-family: 'Luckiest Guy', cursive;
}

body {
  margin: 0;
  font-family: 'Luckiest Guy', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
