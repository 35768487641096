/* Challenge Partner */
.challenge {
    background-color: rgb(13 14 18);
}

.challengeOverlay {
    background: linear-gradient(0deg, #0d0e12, #0d0e1200);
}

.challengeContent p {
    color: white;
}

.challengeQuestPanel {
    background: #232335;
    border: 1px solid rgba(81, 97, 135, .34);
    box-shadow: none;
}

.turbo {
    background-color: rgb(255, 212, 64); 
}

.turboOverlay {
    background: linear-gradient(0deg, #ffd440 1%, #0829aa00);
}

.turboContent p {
    color: black;
}

.turboQuestPanel {
    background: #3b77ff;
    border: 1px solid rgba(1, 4, 14, 0.34);
    box-shadow: none;
}
