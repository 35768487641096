.questSection {
    width: calc(100% - 40px);
    height: auto;
    overflow: hidden;
    border-radius: 6px;
    min-height: 300px;
    margin-top: 30px;
    padding: 20px;
    margin-bottom: 20px;
}

.partnerHero {
    width: calc(100% + 40px);
    margin-left: -20px;
    background-size: cover;
    background-position: center;
    margin-top: -20px;
    position: relative;
    aspect-ratio: 1920/1080;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 2;
}
.content {
    width: 100%;
    height: auto;
    min-height: 40px;
    overflow: hidden;
}

.partnerLogo {
    height: 50px;
    max-width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.title {
    font-size: 21px;
  }
.content p {
    text-align: center;
    font-family: 'Lexend Deca', sans-serif;
}
.video {
    width: 100%;
}


