.profileImage {
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 60px;
  height: 60px;
  float: left;
}
.zoom {
  background-size: 250%;
  background-position: 44% 15%;
}

.overlay {
  width: 100%;
}

.backdrop {
 position: absolute;
 width: 100%;
 top: 0;
 border-radius: 6px;
 left: 0;
 z-index: -1;
 height: 100%;
 background: linear-gradient(0deg, #00aefff0, #00d8fff0);
}