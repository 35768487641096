.countdown {
    width: calc(25% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgba(0,0,0,0.2);
    float: left;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 6px;
  
 }

.countdown small {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
}