.topfade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    background: linear-gradient(0deg, #0048a800, #0048a896);
    z-index: 1;
    pointer-events: none;
  }  

.miniprofile {
    position: absolute;
    width: calc(100% - 20px);
    height: 80px;
    z-index: 2;
    top: 10px;
    left: 10px;    
  }
  
  .profileImg {
    width: 80px;
    border-radius: 6px;
    height: 80px;
    float: left;
    background-size: 250%;
    background-position: 44% 15%;
    position: relative;
    pointer-events: auto;
  }

  .level {
    width: fit-content;
    position: absolute;
    height: 30px;
    top: -5px;
    padding-left: 10px;
    line-height: 34px;
    padding-right: 10px;
    font-family: 'Luckiest Guy', sans-serif;
    color: white;
    text-shadow: 0px 3px 0px rgba(0,0,0,0.4);
    left: -5px;
    background: linear-gradient(0deg, #f48e24, #f1e40c);
    border-radius: 6px;
  }
  .backdrop {
    position: absolute;
    width: 100%;
    top: 0;
    border-radius: 6px;
    left: 0;
    z-index: -1;
    height: 100%;
    background: linear-gradient(0deg, #00aefff0, #00d8fff0);
  }
  
  .levelHolder {
    width: calc(100% - 90px);
    float: left;
    margin-left: 10px;
  }
  
  .levelBar {
    height: 15px;
  }
  
  .bar {
    height: 15px;
  }
  
  .levelInfo p {
    margin-top: 5px;
    color: white;
  }
  
  .coinHolder {
    width: 100px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.25);
    background-size: 100%;
    background-position: center top;
    margin-top: 8px;
    position: relative;
    margin-left: 10px;
    float: right;
    height: 60px;
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    border: 1px solid #002659;
    pointer-events: auto;
  }
  
  .coinHolder p {
    float: left;
    width: 100%;
    text-align: center;
    color: #ffda4c;
    font-family: 'Lexend Deca', sans-serif;
    margin: 0px;
    font-weight: 700;
    text-shadow: 0px 2px 0px #493a003d;
    height: 30px;
    line-height: 29px;
  }
  
  .coinHolder img {
    width: 33px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
    margin-bottom: -4px;
    margin-top: 3px;
  }
  
  .levelHolder h3 {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    width: calc(100% - 130px);  /* Changed from calc(100% - 120px) by FH */
    text-align: left;
    margin: 0px;
    float: left;
    margin-top: 9px;
    margin-bottom: 5px;
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 5vw;
  }

  .currency {
    float: right;
    height: 31px;
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    border: 1px solid #002659;
    border-radius: 50px;
    max-width: 128px;   /* Changed from 118px by FH */
    width: fit-content;
    box-shadow: 0px 2px 0px 0px rgb(62 131 255 / 16%);
    pointer-events: auto;
  }
  .imgIcon {
    width: 30px;
    float: left;
  }
  .currency span {
    font-family: 'Lexend Deca', sans-serif;
    color: #ffcd22;
    float: left;
    display: block;
    height: 28px;
    text-shadow: 0px 2px 0px #69520069;
    line-height: 30px;
    margin-left: 5px;
    font-weight: 700;
    max-width: calc(100% - 35px);
    margin-right: 10px;
    width: fit-content;
  }
  
  .overlay {
    width: 100%;
  }