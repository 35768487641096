@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  
  .animatedImage {
    position: absolute;
    top: 0;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
    will-change: transform;
    transform: translateZ(0); /* Force GPU acceleration */
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-400vw + 100vh));
    }
  }
  
  .pageContent {
    width: calc(100% - 40px);
    height: auto;
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .titleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .headerActions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .marketButton {
    padding: 8px 15px;
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    border: none;
    border-radius: 8px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    gap: 5px;
    transition: transform 0.2s ease;
    will-change: transform;
  }
  
  .marketButton:active {
    transform: scale(0.95);
  }

  .marketButton i {
    font-size: 16px;
  }

  .currencyToggle {
    display: flex;
    gap: 5px;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px;
    border-radius: 30px;
  }

  .toggleBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background: transparent;
    color: white;
    cursor: pointer;
    transition: background 0.2s ease, transform 0.1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform, background;
  
    &.active {
      background: linear-gradient(0deg, #eb7f23, #f1e40c);
    }
  
    &:active {
      transform: scale(0.95);
    }
  
    i {
      font-size: 18px;
    }
  }

  .valueDisplay {
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: -4px;
  
    p {
      position: absolute;
      width: 100%;
      margin: 0;
      transition: opacity 0.2s ease;
      text-align: center;
      font-family: 'Luckiest Guy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-top: 0px;
      margin-bottom: -5px;
      background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      opacity: 1;
      will-change: opacity;
  
      &.active {
        opacity: 1;
      }
    }
  }
  
  .pageTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 42px;
    text-shadow: 0px 3px 11px #00449e63;
    letter-spacing: 1px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .questPanel {
    padding: 5px;
    min-height: 20px;
    width: calc(100% - 10px);
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 2px solid #05e4ff;
    background: linear-gradient(0deg, #00aefff0, #00d8fff0), url('../../assets/images/backgrounds/background-ani.png');
    background-size: 100%;
    box-shadow: 0px 10px 15px 0px #00d3ff42;
    background-position: center top;
    margin-top: 0px;
    position: relative;
    transition: transform 0.2s ease-out;
    will-change: transform, opacity;
  }
  
  .questPanel:active {
    transform: scale(0.98);
  }

.questPanelBadge {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: red;
    color: white;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 12px;
}

  .noCoins {
    filter: sepia(1) hue-rotate(190deg) saturate(2);
    opacity: 0.7;
    box-shadow: none;
  }
  
  .icon {
    float: left;
    position: relative;
    width: 60px;
  }
  
  .tokenTitle {
    float: left;
    margin: 0;
    font-family: 'Luckiest Guy', cursive;
    font-size: 26px;
    line-height: 50px;
    margin-top: 11px;
    margin-left: 9px;
    background: linear-gradient(180deg, #FFFFFF 0%, #DEFBFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: calc(100% - 173px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .actions {
    width: 100px;
    float: right;
    height: 60px;
  }
  
  .actions p {
    margin: 0;
    text-align: center;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 20px;
    line-height: 30px;
    background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .claim {
    width: 100%;
    font-family: 'Luckiest Guy', sans-serif;
    color: white;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    background: url('../../assets/images/backgrounds/small-btn.png');
    padding-top: 3%;
    background-size: cover;
    background-position: center;
    aspect-ratio: 322 / 108;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    will-change: transform;
  }
  
  .claim:active:not(:disabled) {
    transform: scale(0.95);
  }
  
.rewards_page {
    position: relative;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.rewards_header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    background-color: var(--primary-color);
    box-shadow: 0 4px 8px rgba(var(--secondary-color-triplet), 0.2);
}

.rewards_title {
    margin: 0 auto;
    color: var(--white-color);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.back_button {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.back_button img {
    height: 24px;
    width: auto;
}

.divider {
  width: 100%;
  height: 3px;
  background-color: white;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100%;
}

.label {
  margin: 10px 0;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  opacity: 0.7;
}

/* Added by FH */
.status {
  font-family: 'Lexend Deca', sans-serif;
  margin: 10px 0;
  width: 150px; 
  float: right;
  height: 60px;
  color: black;
  font-size: 12px;
}

.status p {
  text-align: center;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 20px;
  line-height: 30px;
  background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
