.battlePassContainer {
    width: calc(100% + 60px);
    overflow-x: hidden;
    margin-left: -30px;
    margin-top: 10px;
}

.battlePassScroll {
    display: flex;
    overflow-x: auto;
    gap: 8px;
    padding-top: 14px;
    padding-bottom: 10px;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
}

.battlePassScroll::-webkit-scrollbar {
    display: none;
}

.rewardItem {
    flex: 0 0 auto;
    scroll-snap-align: start;
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    border: none;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out, opacity 0.3s;
    position: relative;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.rewardItem.locked {
    filter: grayscale(100%);
    opacity: 0.5;
}

.rewardItem.next {
    border: 3px solid #00ff00;
    box-shadow: 0 0 10px #00ff00;
    transform: scale(1.05);
}

.rewardItem.unlocked {
    box-shadow: 0 0 10px #ffd700;
}

.rewardImage img {
    width: 100%;
    height: 70px;
    object-fit: contain;
    /*background-color: #237eff;*/
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rewardInfo {
    padding: 4px 0;
}

.rewardTitle {
    font-size: 10px;
    font-weight: bold;
    color: white;
    text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.4);
}

.rewardFree {
    color: #edcb14;
    font-size: 8px;
    font-weight: bold;
}

.rewardLevel {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #ffd700;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border-radius: 4px;
    padding: 2px 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
}