@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
}

@keyframes moveUp {
    0% {
        top: 0px;
    }
    100% {
        top: calc(-400vw + 100vh);
    }
}

.pageContent {
    width: calc(100% - 40px);
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.profilePanel {
    width: 100%;
    height: auto;
    min-height: 50px;
    overflow: hidden;
    border-radius: 8px;
    background-size: 100%;
    background-position: center top;
    margin-top: 20px;
    float: left;
    position: relative;
    margin-bottom: 10px;
  }

.pageTitle {
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 42px;
  text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
  letter-spacing: 1px;
  margin: 10px 0 15px 0;
}

.titleController {
  display: flex;
  align-items: center;
}
.backBtn img {
  width: 60px;
  margin: 3px 20px 0 0;
}

.profileName {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.4);
}

.leaderboardHeroImg{
    width: 300px;
    margin-bottom: -50px;
    margin-top: -100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    display: block;
}

.divider {
    width: 100%;
    height: 3px;
    background-color: white;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
    opacity: 0.5;
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 100%;
}

.label {
    width: 100%;
    float: left;
    color: white;
    opacity: 0.7;
    margin: 0px;
    margin-bottom: 10px;
    font-family: 'Lexend Deca', sans-serif;
}

