@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Menu.module.css */

.menu {
  top: calc(100vh - calc(99px + calc(env(safe-area-inset-bottom) / 2)));
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;
  height: calc(100px + calc(env(safe-area-inset-bottom) / 2));
  background-image: url('../../assets/images/navigation_tab/menu-bg.png');
  background-size: cover;
  background-position: center top;
  padding-left: 0;
  padding-right: 0;
  /*padding-bottom: calc(env(safe-area-inset-bottom) / 2);*/
}

.menuItem {
  width: 25%;
  padding-top: 14px;
  float: left;
  text-align: center;
  height: 80px;
  position: relative;
}

.menuItem p {
  transition-duration: 0.3s;
  font-family: "Luckiest Guy", serif;
  margin: 0;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  margin-top: 7px;
  letter-spacing: 1px;
  font-weight: 200;
  opacity: 0.6;
  color: white;
}

.menuItem img.menuIcon {
  width: 54px;
  transition-duration: 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.active p {
  transition-duration: 0.3s;
  transform: translateY(-10px) scale(1.1);
  opacity: 1;
}

.active img.menuIcon {
  width: 70px;
  transition-duration: 0.3s;
  transform: translateY(-20px);
}

.badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 12px;
}