@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.digWindow {
  width: 100vw;
  height: 100vh;
  /*background: linear-gradient(0deg, #008000, #00ff00);*/
  /*background: linear-gradient(0deg, #0028ff, #00abff);*/
  /*background: linear-gradient(0deg, #800080, #ff00ff);*/
  /*background: linear-gradient(0deg, #b8860b, #ffd700);*/
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  pointer-events: auto;
  z-index: 2;
  touch-action: none;
}

.rewardHolder {
  display: block;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.closeButton {
  width: 60%;
  aspect-ratio: 803/269;
  background-size: cover;
  background-position: center;
  background-color: transparent;
  border: none;
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 22px;
  padding-top: 1%;
  text-shadow: 0px 0px 5px #0000004f;
}

.closeButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.rewardImg {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 200px;
  max-width: 90%;
  height: 200px;
}

.rewardText {
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 42px;
  text-shadow: 0px 3px 11px #00449e63;
  letter-spacing: 1px;
  margin: 10px 0 15px;
}

.rewardTextSmall {
  font-family: sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-transform: uppercase;
}

.tapped {
  background: linear-gradient(0deg, #0028ff, #2dbaff);
}

.oreHolder {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.ore {
  width: 100%;
  position: absolute;
  transition-duration: 0.05s;
}

.oreHolderTapped {
  transform: scale(0.95);
  transition: transform 0.08s;
}

.banner {
  width: 110%;
  position: absolute;
  top: 40px;
  left: -4%;
}

.particleContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.animatedImage {
  position: absolute;
  top: 0px;
  width: 100%;
  animation: moveUp 30s linear infinite;
  opacity: 0.033;
  pointer-events: none;
}

.textHolder {
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 0;
}

.digText {
  width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  font-family: 'Luckiest Guy', serif;
  font-size: 24px;
}

.digText::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 2px;
  right: -110px;
  top: 10px;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.digText::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 2px;
  left: -110px;
  top: 10px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@keyframes moveUp {
  0% {
    top: 0px;
  }
  100% {
    top: calc(-400vw + 100vh);
  }
}

.spinnerBackground {
  position: absolute;
  width: 200vw;
  height: 200vw;
  left: calc(50% - 100vw);
  background: repeating-conic-gradient(#00000000 0deg 10deg, #ffffff 10deg 20deg);
  animation: spin 20s linear infinite;
  border-radius: 50%;
  mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
  -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
  opacity: 0.2;
  pointer-events: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}