@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
}

@keyframes moveUp {
    0% {
        top: 0px;
    }
    100% {
        top: calc(-400vw + 100vh);
    }
}

.pageContent {
    width: calc(100% - 40px);
    height: 100vh;
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.pageContent::-webkit-scrollbar {
    display: none;
}

.titleController {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.backBtn img {
    width: 60px;
    margin: 3px 20px 0 0;
}

.pageTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 42px;
    text-shadow: 0px 3px 11px #00449e63;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 0;
    width: fit-content;
}

.tabContainer {
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden;
}

.tabButton {
    flex: 1;
    padding: 12px;
    background: transparent;
    border: none;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tabButton.active {
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.tabContent {
    margin-top: 20px;
}

.listingsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.loadMoreButton {
    width: 100%;
    margin-top: 15px;
    padding: 12px;
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    border: none;
    border-radius: 8px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s;
}

.loadMoreButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.createListingButton {
    padding: 10px 15px;
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    border: none;
    border-radius: 8px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    margin-top: 10px;
}

.myListingsHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #f1e40c;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loadingContainer p {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
}

.emptyState {
    text-align: center;
    padding: 40px 0;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
}

.emptyState p {
    margin-bottom: 20px;
    opacity: 0.8;
}
