@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
}

@keyframes moveUp {
    0% {
        top: 0px;
    }
    100% {
        top: calc(-400vw + 100vh);
    }
}

.scrollableContent {
  width: 100%;
  height: 100%;
  overflow-y: auto;
/* Hide scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.pageContent {
    width: calc(100% - 40px);
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.pageTitle {
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 42px;
  text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
  letter-spacing: 1px;
  margin: 10px 0 15px 0;
}

.titleController {
  display: flex;
  align-items: center;
}
.backBtn img {
  width: 60px;
  margin: 3px 20px 0 0;
}
.pageTitle {
  width: fit-content;
}
.profilePanel {
  margin: 20px 0 0px;
  padding: 10px;
  border-radius: 8px;
  background-size: 100%;
  background-position: center top;
}
.profileImage {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.zoom {
  background-size: 250%;
  background-position: 44% 15%;
}

.overlay {
  width: 100%;
}

.backdrop {
  position: absolute;
  width: 100%;
  top: 0;
  border-radius: 6px;
  left: 0;
  z-index: -1;
  height: 100%;
  background: linear-gradient(0deg, #00aefff0, #00d8fff0);
 }

.profileName {
  margin: 20px 0;
  text-align: center;
  font-size: 30px;
  color: white;
  text-shadow: 0 3px 0 rgba(0, 0, 0, 0.4);
  font-family: 'Luckiest Guy', sans-serif;
}

.levelInfo {
  display: flex;
  justify-content: space-between;
  color: #ffd800;
  font-size: 14px;
  font-weight: 700;
}

.levelInfo p {
  margin: 0px;
  width: 50%;
  float: left;
  font-family: 'Lexend Deca', sans-serif;
  color: #ffd800;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 700;
  text-shadow: 0px 2px 0px #8c5b0069;
}

.right {
  text-align: right;
}

.divider {
  width: 100%;
  height: 3px;
  background-color: white;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100%;
}
.label {
  margin: 10px 0;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  opacity: 0.7;
}
.wallets {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.wallet {
  width: calc(33.33% - 10px);
  aspect-ratio: 126 / 179;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.wallet:hover {
  transform: scale(1.05);
}

.sol {
  background-image: url('../../assets/images/profile/solana-wallet.png');
}
.ton {
  background-image: url('../../assets/images/profile/ton-wallet.png');
}
.connected.evm {
  background-image: url('../../assets/images/profile/evm-wallet-connected.png');
}
.connected.sol {
  background-image: url('../../assets/images/profile/solana-wallet-connected.png');
}
.connected.ton {
  background-image: url('../../assets/images/profile/ton-wallet-connected.png');
}
.middle {
  margin: 0 15px;
}

.referralStats {
  width: 100%;
  min-height: 20px;
  overflow: hidden;
  position: relative;
  padding-bottom: 5px;
}

.referralStat {
  width: calc(50% - 28px);
  float: left;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
  height: 60px;
  background: linear-gradient(0deg, #0167ff, #01a9ff);
  padding: 5px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #002659;
  margin-bottom: 10px;
}

.statIcon {
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 10px;
}

.referralStatFirst {
  margin-right: 20px;
}

.referralStat p {
  float: left;
  width: calc(100% - 62px);
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 24px;
  line-height: 27px;
  margin: 0;
  margin-top: 4px;
}

.referralStat span {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 15px;
  opacity: 0.6;
}

.achievements {
  width: 100%;
  height: auto;
  min-height: 600px;
}
.achievement {
  width: calc(33% - 10px);
  background-size: cover;
  background-position: center;
  aspect-ratio: 126 / 212;
  opacity: 0.3;
  filter: sepia(1) hue-rotate(190deg) saturate(2);
  float: left;
  margin-bottom: 15px;
}
.achievement.middle {
  margin: 0 15px;
}


/* Swal */
.container {
  background: linear-gradient(0deg, #0167ff, #01a9ff);
  border: 1px solid #042b64;
  box-shadow: 0px 5px 0px 0px #042b64ba;
}
.input {
  height: 3.625em;
  padding: 0 0.75em;
  width: calc(100% - 30px);
  margin-left: 15px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  font-size: 20px;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 300;
}
.title {
  color: white !important;
  font-size: 22px !important;
  margin-top: 20px !important;
  font-family: 'Lexend Deca', sans-serif;
}
.text {
  color: white !important;
  font-family: 'Lexend Deca', sans-serif;
}
.errorText {
  color: black !important;
  font-family: 'Lexend Deca', sans-serif;
}
.confirmButton {
  background: linear-gradient(0deg, #eb7f23, #f1e40c) !important;
  text-align: center;
  line-height: 30px;
  border-radius: 6px !important;
  border: 1px solid #c15111 !important;
  color: white !important;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600 !important;
  text-shadow: 0px 0px 3px #0000002b;
}
.cancelButton {
  background: rgba(255, 255, 255, 0.3) !important;
  text-align: center;
  line-height: 30px;
  border-radius: 6px !important;
  border: 1px solid #c1511100 !important;
  color: white !important;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 300 !important;
}

.connectWalletsButton {
  width: 60%;
  display: block;
  margin: 20px auto;
  background: linear-gradient(0deg, #eb7f23, #f1e40c);
  border: none;
  border-radius: 6px;
  color: white;
  padding: 12px 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 0px 0px 3px #0000002b;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.connectWalletsButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.connectWalletsButton:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.verificationCode {
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 6px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.verificationCode input {
  background: none;
  border: none;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  width: 60%;
  outline: none;
  padding: 5px;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
  text-align: center;
}

.copyButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.copyButton {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}
.swalBackdrop {
  background: rgb(0 24 43 / 81%);
  backdrop-filter: blur(10px);
}

.customizeCharacterPopupList {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    flex-wrap: wrap;

}

.rewardItem {
  flex: 0 0 auto;
  scroll-snap-align: start;
  background: linear-gradient(0deg, #eb7f23, #f1e40c);
  border: none;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out, opacity 0.3s;
  position: relative;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.rewardItem.locked {
  filter: grayscale(100%);
  opacity: 0.5;
}

.rewardItem.next {
  border: 3px solid #00ff00;
  box-shadow: 0 0 10px #00ff00;
  transform: scale(1.05);
}

.rewardItem.unlocked {
  box-shadow: 0 0 10px #ffd700;
}

.rewardImage img {
  width: 100%;
  height: 70px;
  object-fit: contain;
  /*background-color: #237eff;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rewardInfo {
  padding: 4px 0;
}

.rewardTitle {
  font-size: 10px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.4);
}

.rewardFree {
  color: #edcb14;
  font-size: 8px;
  font-weight: bold;
}

.rewardLevel {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffd700;
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
}
