@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: -1;
  }
  
  .animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
  }

  @keyframes moveUp {
    0% {
      top: 0px; /* Start below the visible area */
    }
    100% {
      top: calc(-400vw + 100vh); /* End when the top of the image is fully visible */
    }
  }

  .pageContent {
    width: calc(100% - 40px);
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.pageTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 42px;
    text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
    letter-spacing: 1px;
    margin: 10px 0 15px 0;
}

.referralPanel {
    width: 100%;
    aspect-ratio: 406/218;
    margin-bottom: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.buttonContainer {
    position: absolute;
    width: calc(100% - 30px);
    aspect-ratio: 406/80;
    bottom: 0;
    padding: 0 15px;
}

.refBtn {
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 22px;
    width: calc(50% - 10px);
    float: left;
    aspect-ratio: 181 / 50;
    border-radius: 6px;
    border: 2px solid rgba(239, 132, 35, 0.34);
    background-color: white;
    box-shadow: 0px 3px 10px 0px rgb(210 113 26);
    color: #ff8e30;
    padding-top: 2%;
}

.last {
    margin-left: 20px;
}

.referralPanel {
    width: 100%;
    aspect-ratio: 406/218;
    margin-bottom: 12px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.questPanel {
    padding: 5px;
    min-height: 20px;
    width: calc(100% - 10px);
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 2px solid #05e4ff;
    background: linear-gradient(0deg, #00aefff0, #00d8fff0), url('../../assets/images/backgrounds/background-ani.png');
    background-size: 100%;
    box-shadow: 0px 10px 15px 0px #00d3ff42;
    background-position: center top;
    margin-top: 0px;
    position: relative;
  }
  
  .vault {
    padding: 5px;
  }
  
 
  .profileImage {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
  }
  
  .overlay {
    width: 100%;
  }

  .tokenTitle {
    float: left;
    margin: 0px;
    font-family: 'Luckiest Guy';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    background: linear-gradient(180deg, #FFFFFF 0%, #DEFBFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  
    margin-top: 11px;
    line-height: 50px;
    margin-left: 9px;
  
    width: calc(100% - 173px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .actions {
    margin-left: auto;
    text-align: right;
  }
  
  .actions p {
    margin: 0;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 20px;
    line-height: 30px;
    background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .claim {
    width: 100%;
    font-family: 'Luckiest Guy', sans-serif;
    color: white;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    background: url('../../assets/images/backgrounds/small-btn.png');
    padding-top: 3%;
    background-size: cover;
    background-position: center;
    aspect-ratio: 322 / 108;
    border: none;
    cursor: pointer; /* FH Pointer on hover */
  }

  .accept {
    filter: hue-rotate(69deg)
  }
  .deny {
    filter: hue-rotate(307deg);
  }
  .waiting {
    filter: sepia(1) hue-rotate(190deg) saturate(2);
    opacity: 0.6;
  }
  .imgLoader {
    width: 10px;
  }
  .actionFriends {
    width: 93px;
    display: flex;
    align-items: center;
    float: right;
    height: 60px;
  }
  .actionsFriendsHolder {
    width: 100%;
  }

