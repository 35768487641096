.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.popupContent {
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    background: linear-gradient(135deg, #1a2a6c, #0083B0);
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
    from { transform: translateY(50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.popupTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 24px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.closeButton:hover {
    transform: scale(1.1);
    color: #ff5555;
}

.itemsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    padding: 20px;
    overflow-y: auto;
    max-height: calc(80vh - 70px);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.itemsGrid::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.itemCard {
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
}

.itemCard:hover {
    transform: scale(1.05);
}

.selectedCard {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
    transform: scale(1.05);
}

.selectedBadge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #ffd700;
    color: #000;
    padding: 5px 10px;
    border-radius: 12px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    z-index: 1;
}

.spinner {
    width: 30px;
    height: 30px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.itemImage {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
}

.itemImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.noneIcon {
    font-size: 32px;
    color: #ff5555;
}

.itemInfo {
    margin-top: 8px;
}

.itemName {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 16px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.4);
    margin: 0;
}

.descriptionContainer {
    position: relative;
    margin: 4px 0;
}

.itemDescription {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    margin: 0;
    line-height: 1.3;
    transition: max-height 0.3s ease;
    overflow: hidden;
    max-height: 36px; /* Approximately 2 lines of text */
}

.itemDescription.expanded {
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.itemDescription.expanded::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.expandButton {
    background: none;
    border: none;
    color: #4dabff;
    font-size: 10px;
    padding: 2px 0;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    text-decoration: underline;
    margin-top: 2px;
    transition: color 0.2s;
}

.expandButton:hover {
    color: #7fc5ff;
}

.itemRarity {
    color: #ffd800;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-shadow: 0px 2px 0px rgba(140, 91, 0, 0.4);
    margin: 0;
}
