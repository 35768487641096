.levelBar {
    height: 25px;
    background-color: rgb(82 196 254 / 24%);
    border-radius: 6px;
    overflow: hidden;
  }
  .bar {
    width: 30%;
    height: 100%;
    background: linear-gradient(0deg, #f48e24, #f1e40c);
    border-radius: 0 6px 6px 0;
    box-shadow: 3px 0 0 #f9f2b03d;
  }