.listingCard {
    background: linear-gradient(0deg, #00aefff0, #00d8fff0);
    border: 2px solid #05e4ff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 10px 15px 0px #00d3ff42;
    margin-bottom: 15px;
    transition: transform 0.2s;
    position: relative;
}

.featured {
    border: 2px solid gold;
    animation: shinyBorder 2s infinite alternate;
}

@keyframes shinyBorder {
    from {
        box-shadow: 0px 10px 15px 0px #00d3ff42, 0 0 5px gold;
    }
    to {
        box-shadow: 0px 10px 15px 0px #00d3ff42, 0 0 15px gold;
    }
}

.featuredTag {
    position: absolute;
    top: -10px;
    right: 10px;
    background: linear-gradient(0deg, #f1a70c, #ffd700);
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 4px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.listingCard:hover {
    transform: translateY(-2px);
}

.listingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.listingTitle {
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 20px;
    color: white;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.listingStatus {
    padding: 4px 8px;
    border-radius: 4px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.available {
    background-color: #4CAF50;
    color: white;
}

.expired {
    background-color: #FFC107;
    color: #333;
}

.removed {
    background-color: #F44336;
    color: white;
}

.purchased {
    background-color: #2196F3;
    color: white;
}

.listingContent {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.listingImage {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 6px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
}

.listingImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.listingDetails {
    flex: 1;
}

.listingDescription {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    color: white;
    margin-bottom: 10px;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.tokenDetails {
    display: flex;
    gap: 0 7px;
    align-items: center;
    flex-wrap: wrap;
}

.tokenTitle {
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 20px;
    color: white;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.valueDisplay {
    height: 30px;
}

.valueDisplay p {
    width: 100%;
    margin: 0;
    transition: opacity 0.3s ease;
    font-family: 'Luckiest Guy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
}

.listingInfo {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
}

.infoItem {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    color: white;
}

.infoLabel {
    font-weight: 600;
    margin-right: 5px;
    opacity: 0.8;
}

.infoValue {
    font-weight: 400;
}

.countdownNormal {
    color: white;
}

.countdownWarning {
    color: #FFC107;
    font-weight: 600;
}

.countdownCritical {
    color: #F44336;
    font-weight: 700;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

.listingActions {
    display: flex;
    justify-content: flex-end;
}

.purchaseButton, .removeButton {
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Hide number input arrows in different browsers */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.purchaseButton {
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.removeButton {
    background-color: #F44336;
    color: white;
}

.purchaseButton:disabled, .removeButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
