.bodyPartSelector {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    margin-bottom: 15px;
    justify-content: center;
    max-width: 100%;
    padding: 0 5px;
}

.partButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 4px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: auto;
    position: relative;
}

.partButton:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.partButton:hover::after {
    content: "Tap to edit";
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 8px;
    white-space: nowrap;
    z-index: 10;
}

.partButton.selected {
    background: linear-gradient(0deg, #ffd700, #ffa500);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
}

.partButton img {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
}

.emptyBox {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
    border: 2px dashed rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyBox::before {
    content: "+";
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
}

.partButton span {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0 2px;
}
