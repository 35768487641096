.overlay_content_title {
    font-size: 22px;
    font-weight: bold;
    margin: 0 0 20px;
    color: var(--white-color);
}

.overlay_content_message {
    font-size: 22px;
    margin: 0 0 20px;
    color: var(--white-color);
}

.overlay_content_message_above_input {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 4px;
    color: var(--white-color);
}

.overlay_content_buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.overlay_content_confirm_button {
    padding: 8px 12px;
    background-color: var(--tertiary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
}

.overlay_content_cancel_button {
    padding: 8px 12px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
}

.overlay_content_input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--tertiary-color);
    box-sizing: border-box;
    margin-bottom: 0;
    font-family: 'Luckiest Guy', cursive;
    font-size: 18px;
}

.overlay_content_input_error {
    font-size: 16px;
    color: red;
    margin-top: 6px;
}