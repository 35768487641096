.questPanel {
    padding: 15px;
    width: calc(100% - 30px);
    min-height: 50px;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid #05e4ff;
    background: linear-gradient(0deg, #00aefff0, #00d8fff0),
        url('../../../assets/images/backgrounds/background-ani.png');
    background-size: 100%;
    box-shadow: 0px 10px 15px 0px rgba(0, 211, 255, 0.26);
    background-position: center top;
    margin-top: 0px;
    padding-bottom: 70px;
    position: relative;
    margin-bottom: 10px;
  }
  
  .vault {
    padding: 5px;
    min-height: 20px;
    width: calc(100% - 10px);
    margin-bottom: 10px;
  }

.placement {
    position: absolute;
    left: 0px;
    top: 0;
    background: #ffad00cc;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    text-align: center;
    z-index: 10;
    border: 2px solid #ffffff70;
    line-height: 31px;
    color: #ffffff;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 20px;
    text-shadow: 0px 0px 4px #00000008;
}

.tokenTitle {
    float: left;
    margin: 0px;
    font-family: 'Luckiest Guy';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    background: linear-gradient(180deg, #FFFFFF 0%, #DEFBFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  
    margin-top: 11px;
    line-height: 50px;
    margin-left: 9px;
  
    width: calc(100% - 173px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.tokenTitle {
    -webkit-text-fill-color: unset;
    background: unset;
    background-clip: unset;
    line-height: 27px;
    margin-top: 5px;
    color: white;
  }

.tokenTitle small {
    color: #ffd130;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.actions {
    width: 100px;
    float: right;
    height: 60px;
}

.actions p {
    width: 100%;
    float: left;
    margin: 0px;
    text-align: center;
    font-family: 'Luckiest Guy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: -5px;
    background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

.friends {
    width: 93px;
    display: flex;
    align-items: center;
}

.holder {
    width: 100%;
}

.rewardIcon {
    width: 30px;
    margin-left: auto;
    display: block;
    margin-right: auto;
  }