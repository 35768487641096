@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
}

@keyframes moveUp {
    0% {
        top: 0px;
    }
    100% {
        top: calc(-400vw + 100vh);
    }
}

.pageContent {
    width: calc(100% - 40px);
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.titleController {
  display: flex;
  align-items: center;
}

.backBtn img {
  width: 60px;
  margin: 3px 20px 0 0;
}

.pageTitle {
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 42px;
  text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
  letter-spacing: 1px;
  margin: 10px 0 15px 0;
  width: fit-content;
}

.customizationPanel {
  margin: 20px 0;
  padding: 10px;
  border-radius: 8px;
}

.sectionTitle {
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 24px;
  text-shadow: 0 3px 0 rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.itemCard {
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  position: relative;
}

.selectedCard {
  background: linear-gradient(0deg, #ffd700, #ffa500);
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  transform: scale(1.05);
}

.selectedBadge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ffd700;
  color: #000;
  padding: 5px 10px;
  border-radius: 12px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 1;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.itemCard:hover {
  transform: scale(1.05);
}

.itemImage img {
  width: 100%;
  height: 100px;
  object-fit: contain;
  border-radius: 4px;
}

.defaultIcon {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.itemInfo {
  margin-top: 8px;
}

.itemName {
  color: white;
  font-family: 'Luckiest Guy', sans-serif;
  font-size: 16px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.4);
  margin: 0;
}

.itemDescription {
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  margin: 4px 0;
}

.itemRarity {
  color: #ffd800;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-shadow: 0px 2px 0px #8c5b0069;
  margin: 0;
}

.divider {
  width: 100%;
  height: 3px;
  background-color: white;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100%;
}

.disabledSection {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
