.spinnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*width: 100%;*/
    margin-left: -20px;
    margin-right: -20px;
    width: auto;
    overflow: visible; /* Ensures the spinning items don’t overflow the container */
    border-radius: 8px;
    /*padding: 20px;*/
}

.spinner {
    display: flex;
    overflow-x: visible; /* Prevent horizontal scroll */
    scroll-behavior: smooth;
    padding: 10px 0;
    white-space: nowrap;
    transition: transform 0.2s ease-in-out;
}

.itemCard {
    flex: 0 0 auto; /* Prevent shrinking or growing */
    width: 100px; /* Matches ITEM_WIDTH */
    height: 150px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s, background 0.2s;
}

/* itemCard with data-selected="true"*/
.itemCard[data-selected="true"] {
    transform: translateY(-5px); /* Slight hover effect */
}

.itemImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 0px;
    margin-top: 10px;
}

.itemInfo {
    text-align: center;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    margin-top: 0px;
    text-wrap: wrap;
}

.itemInfo h3 {
    margin: 0;
}

.spinButton {
    margin-top: 40px;
    padding: 16px 48px;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 24px;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
}

.spinButton:not(:disabled)::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
    transform: translate(-75%, -75%);
    animation: shimmer 3s infinite linear;
}

.spinButton:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.lineSelector {
    position: absolute;
    width: 2px;
    background: rgba(255, 215, 0, 1);
    box-shadow:
            0 0 20px 10px rgba(255, 215, 0, 0.3),
            0 0 40px 20px rgba(255, 215, 0, 0.2);
    animation: glow 1.5s ease-in-out infinite;
}

@keyframes glow {
    0%, 100% { opacity: 0.7; }
    50% { opacity: 1; }
}

@keyframes shimmer {
    from {
        transform: translate(-75%, -75%) rotate(0deg);
    }
    to {
        transform: translate(-75%, -75%) rotate(360deg);
    }
}