.modalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 15px;
    min-height: 48px;
}

.title {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 32px;
    text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
    letter-spacing: 1px;
    margin: 0;
}

.closeButton {
    background: transparent;
    border: none;
    color: white;
    font-size: 32px;
    cursor: pointer;
    padding: 8px;
    line-height: 1;
}

.content {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.content::-webkit-scrollbar {
    display: none;
}

.section {
    margin-bottom: 30px;
}

.sectionTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 24px;
    margin-bottom: 15px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    padding-left: 20px;
}

.itemsGrid, .offersGrid {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding-bottom: 10px;
    padding-right: 40px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.itemsGrid::-webkit-scrollbar, .offersGrid::-webkit-scrollbar {
    display: none;
}

.itemCard, .offerCard {
    flex: 0 0 80%;
    max-width: 225px;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.itemsGrid > .itemCard:first-child, .itemsGrid > .offerCard:first-child {
    margin-left: 20px;
}

.offersGrid > .offerCard:first-child, .offersGrid > .offerCard:first-child {
    margin-left: 20px;
}

.itemImage, .offerImage {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
}

.itemInfo, .offerInfo {
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.itemName, .offerName {
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 18px;
    margin: 0 0 5px 0;
}

.itemDescription {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    margin: 0 0 10px 0;
    opacity: 0.9;
}

.itemRarity {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.6);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Lexend Deca', sans-serif;
}

.offerCost {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.activationSection {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
}

.activationCost {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 0; /* Prevent flex item from overflowing */
}

.activationCost p {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 20px;
    margin: 0;
    white-space: nowrap;
}

.costAmount {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 18px;
    min-width: 0; /* Prevent flex item from overflowing */
    flex-wrap: wrap; /* Allow wrapping if needed */
}

.openButton {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 16px 32px;
    background: linear-gradient(180deg, #F1E40C 0%, #EB7F23 100%);
    border: none;
    border-radius: 12px;
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 12px rgba(235, 127, 35, 0.4);
}

.openButton:hover:not(:disabled) {
    transform: translateY(-2px) scale(1.02);
    box-shadow: 0 6px 16px rgba(235, 127, 35, 0.6);
}

.openButton:disabled {
    background: linear-gradient(180deg, #808080 0%, #606060 100%);
    cursor: not-allowed;
    opacity: 0.7;
}

.loading, .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
}

.spinner {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.container {
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    border: 1px solid #042b64;
    box-shadow: 0px 5px 0px 0px #042b64ba;
}

.input {
    height: 3.625em;
    padding: 0 0.75em;
    width: calc(100% - 30px);
    margin-left: 15px;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    font-size: 20px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 300;
}

.title {
    font-family: 'Luckiest Guy', sans-serif !important;
    font-size: 22px !important;
    color: white !important;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4) !important;
}

.text {
    color: white !important;
    font-family: 'Lexend Deca', sans-serif;
}

.errorText {
    color: black !important;
    font-family: 'Lexend Deca', sans-serif;
}

.confirmButton {
    background: linear-gradient(0deg, #eb7f23, #f1e40c) !important;
    text-align: center;
    line-height: 30px;
    border-radius: 6px !important;
    border: 1px solid #c15111 !important;
    color: white !important;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600 !important;
    text-shadow: 0px 0px 3px #0000002b;
}

.cancelButton {
    background: rgba(255, 255, 255, 0.3) !important;
    text-align: center;
    line-height: 30px;
    border-radius: 6px !important;
    border: 1px solid #c1511100 !important;
    color: white !important;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 300 !important;
}

.swalBackdrop {
    background: rgb(0 24 43 / 81%);
    backdrop-filter: blur(10px);
}