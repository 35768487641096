/* Swal */
.container {
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    border: 1px solid #042b64;
    box-shadow: 0px 5px 0px 0px #042b64ba;
  }
  .input {
    height: 3.625em;
    padding: 0 0.75em;
    width: calc(100% - 30px);
    margin-left: 15px;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    font-size: 20px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 300;
  }
  .title {
    color: white !important;
    font-size: 22px !important;
    margin-top: 20px !important;
    font-family: 'Lexend Deca', sans-serif;
  }
  .text {
    color: white !important;
    font-family: 'Lexend Deca', sans-serif;
  }
  .confirmButton {
    background: linear-gradient(0deg, #eb7f23, #f1e40c) !important;
    text-align: center;
    line-height: 30px;
    border-radius: 6px !important;
    border: 1px solid #c15111 !important;
    color: white !important;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600 !important;
    text-shadow: 0px 0px 3px #0000002b;
  }
  .cancelButton {
    background: rgba(255, 255, 255, 0.3) !important;
    text-align: center;
    line-height: 30px;
    border-radius: 6px !important;
    border: 1px solid #c1511100 !important;
    color: white !important;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 300 !important;
  }
  .backdrop {
    background: rgb(0 24 43 / 81%);
    backdrop-filter: blur(10px);
  }