.filtersContainer {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.searchBar {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.searchInput {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-family: 'Lexend Deca', sans-serif;
}

.searchInput::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.searchButton {
    padding: 10px 15px;
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    border: none;
    border-radius: 6px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    cursor: pointer;
}

.expandButton {
    width: 100%;
    padding: 8px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 6px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
}

.expandButton:hover {
    background: rgba(255, 255, 255, 0.3);
}

.expandedFilters {
    margin-top: 15px;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

.filterRow {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.filterGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.filterLabel {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    color: white;
    margin-bottom: 5px;
    opacity: 0.8;
}

.filterSelect {
    padding: 8px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 6px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
}

.filterSelect option {
    background-color: #0066cc;
    color: white;
}

.checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    cursor: pointer;
}

.checkboxLabel input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.filterActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.resetButton, .applyButton {
    padding: 8px 15px;
    border: none;
    border-radius: 6px;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    cursor: pointer;
}

.resetButton {
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

.applyButton {
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    color: white;
}
