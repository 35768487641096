.homePage {
    text-align: center;
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2; /* Ensure it is on top of UnityPage */
}

/* Pulsating loading effect */
.loadingIcon {
    width: 80px;
    height: 80px;
    animation: heartbeat 1s infinite;
    position: absolute;
/*     Center top */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadingGif {
    width: auto;
    height: calc(100vh - 300px);
}

@keyframes heartbeat
{
    0%
    {
        transform: scale( .75 );
    }
    20%
    {
        transform: scale( 1 );
    }
    40%
    {
        transform: scale( .75 );
    }
    60%
    {
        transform: scale( 1 );
    }
    80%
    {
        transform: scale( .75 );
    }
    100%
    {
        transform: scale( .75 );
    }
}

.loadStatusText {
    color: var(--white-color);
    text-align: center;
    /*margin: 0;*/
    font-size: 16px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1;
    height: calc(3 * 16px);
    font-family: 'Luckiest Guy', serif;
}

/*.loadStatusText:after {*/
/*    overflow: hidden;*/
/*    display: inline-block;*/
/*    vertical-align: bottom;*/
/*    -webkit-animation: ellipsis steps(4, end) 900ms infinite;*/
/*    animation: ellipsis steps(4, end) 900ms infinite;*/
/*    content: "\2026";*/
/*    width: 0;*/
/*}*/

/*@keyframes ellipsis {*/
/*    to {*/
/*        width: 16px;*/
/*    }*/
/*}*/

/*@-webkit-keyframes ellipsis {*/
/*    to {*/
/*        width: 16px;*/
/*    }*/
/*}*/

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.errorList {
    margin-top: 20px;
    color: red;
}

.errorItem {
    margin-bottom: 10px;
}

.helpIcon {
    font-size: 14px;
    margin-right: 5px;
}

.retryButton {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.retryButton:hover {
    background-color: #0056b3;
}

.retryIcon {
    font-size: 14px;
    margin-right: 5px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.closeButton {
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.modalBody {
    margin-bottom: 10px;
}

.loadStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

.statusIconSuccess {
    color: green;
    margin-left: 8px;
}

.statusIconFailure {
    color: red;
    margin-left: 8px;
}

.lockIcon {
    color: gray;
    margin-left: 8px;
}

.modalFooter {
    display: flex;
    justify-content: space-between;
}

.showMapButton, .closeModalButton {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.showMapButton:hover, .closeModalButton:hover {
    background-color: #0056b3;
}

.mapDisplay {
    padding: 20px;
    font-size: 24px;
}

.loadStatusButton {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.telegramModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.telegramModal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease;
}

.telegramModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.telegramModalHeader h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.closeButton {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

.telegramModalBody {
    margin-bottom: 20px;
}

.usernameInput {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.usernameError {
    color: red;
    margin-top: 5px;
}

.telegramModalFooter {
    display: flex;
    justify-content: flex-end;
}

.submitButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}