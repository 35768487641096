.flowContainer {
    width: 100%;
    height: 100vh;
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    margin: 0;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    animation: fadeIn 0.3s ease-out;
    display: flex;
    flex-direction: column;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.flowHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.flowHeader h2 {
    margin: 0;
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 24px;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.progressContainer {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    background: rgba(0, 0, 0, 0.05);
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: auto;
}

.progressContainer::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-50%);
    z-index: 1;
}

.progressStep {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    position: relative;
    z-index: 2;
}

.progressStep.active {
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    box-shadow: 0 0 10px rgba(241, 228, 12, 0.5);
}

.progressStep.completed {
    background: #4CAF50;
}

.flowContent {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.flowContent::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.stepContent {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.stepTitle {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
}

.formGroup {
    margin-bottom: 20px;
    width: 100%;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
}

.formGroup input,
.formGroup select {
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    box-sizing: border-box;
}

.formGroup select option {
    background-color: #0066cc;
    color: white;
}

.inputError {
    border-color: #F44336 !important;
}

.errorText {
    color: #F44336;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    margin-top: 5px;
}

.infoText {
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    margin-top: 5px;
}

.durationControls {
    display: flex;
    gap: 8px;
    margin-top: 10px;
    flex-wrap: wrap;
}

.durationControl {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 6px 8px;
    flex: 1;
    min-width: 120px;
}

.durationLabel {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    margin-right: 8px;
    min-width: 45px;
}

.durationButtons {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
}

.durationButton {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.durationButton:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.3);
}

.durationButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.durationValue {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    font-weight: 600;
    min-width: 20px;
    text-align: center;
}

.selectedItemPreview {
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.itemImage {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
}

.itemImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.itemDetails {
    margin-left: 15px;
    flex: 1;
}

.itemDetails h4 {
    margin-top: 0;
    margin-bottom: 8px;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
}

.itemDetails p {
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
}

.pricePreview {
    margin-top: 8px !important;
    font-weight: 600 !important;
    color: #FDF041 !important;
}

.listingTypeSelection {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.listingTypeOption {
    flex: 1;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.listingTypeOption:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
}

.listingTypeOption.selected {
    border-color: #f1e40c;
    background: rgba(241, 228, 12, 0.1);
    box-shadow: 0 0 10px rgba(241, 228, 12, 0.3);
}

.listingTypeIcon {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-size: 20px;
}

.listingTypeDetails {
    flex: 1;
}

.listingTypeDetails h4 {
    margin: 0 0 5px 0;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
}

.listingTypeDetails p {
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkboxContainer input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.checkboxContainer label {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    margin-bottom: 0;
}

.usdValue {
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    font-style: italic;
    margin-top: 4px !important;
}

.usdValueInline {
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    font-style: italic;
    margin-left: 4px;
}

.valueDisplay {
    height: 30px;
}

.valueDisplay p {
    width: 100%;
    margin: 0;
    transition: opacity 0.3s ease;
    font-family: 'Luckiest Guy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
}

.valueDisplay p span {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    font-style: italic;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
    filter: none;
}

.reviewCard {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 20px;
}

.reviewHeader {
    display: flex;
    margin-bottom: 20px;
}

.reviewDetails {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 15px;
}

.reviewItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.reviewLabel {
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Lexend Deca', sans-serif;
}

.reviewValue {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
}

.flowActions {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.1);
}

.backButton,
.cancelButton,
.nextButton,
.createButton {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600;
    cursor: pointer;
}

.backButton {
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

.cancelButton {
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

.nextButton {
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.createButton {
    background: linear-gradient(0deg, #eb7f23, #f1e40c);
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
