@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.questPanel {
  padding: 15px;
  width: calc(100% - 30px);
  min-height: 50px;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid #05e4ff;
  background: linear-gradient(0deg, #00aefff0, #00d8fff0),
      url('../../../assets/images/backgrounds/background-ani.png');
  background-size: 100%;
  box-shadow: 0px 10px 15px 0px rgba(0, 211, 255, 0.26);
  background-position: center top;
  margin-top: 0px;
  padding-bottom: 70px;
  position: relative;
  margin-bottom: 10px;
}

.vault {
  padding: 5px;
  min-height: 20px;
  width: calc(100% - 10px);
  margin-bottom: 10px;
}

.profileImage {
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 60px;
  height: 60px;
  float: left;
}

.overlay {
  width: 100%;
}

.backdrop {
 position: absolute;
 width: 100%;
 top: 0;
 border-radius: 6px;
 left: 0;
 z-index: -1;
 height: 100%;
 background: linear-gradient(0deg, #00aefff0, #00d8fff0);
}

.tokenTextHolder {
  width: calc(100% - 173px);
  float: left;
  height: 60px;
  display: flex;
  align-items: center;
}

.tokenTitle {
  float: left;
  margin: 0px;
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  background: linear-gradient(180deg, #FFFFFF 0%, #DEFBFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  margin-top: 11px;
  line-height: 50px;
  margin-left: 9px;

  width: calc(100% - 173px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tokenTitleH5 {
  width: 100%;
  float: unset;
  font-family: 'Lexend Deca', sans-serif !important;
  font-size: 14px;
  margin: 0px;
  margin-left: 9px;
  text-overflow: ellipsis;
  line-height: 17px;
  display: block;
  overflow: hidden; /* Ensure overflow is hidden */
  display: -webkit-box; /* Required for -webkit-line-clamp */
  -webkit-line-clamp: 3; /* Limit to 2 lines */
  -webkit-box-orient: vertical; /* Set the box orientation */
  white-space: unset;
}


.actions {
  width: 93px;
  display: flex;
  align-items: center;
  float: right;
  height: 60px;
}
.actions p {
  width: 100%;
  float: left;
  margin: 0px;
  text-align: center;
  font-family: 'Luckiest Guy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: -5px;
  background: linear-gradient(180deg, #FDF041 0%, #EFB602 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.actions p img {
  width: 23px;
  float: left;
}

.holder {
  width: 100%;
}

.loader {
  width: 10px;
}

.claim {
  width: 100%;
  font-family: 'Luckiest Guy', sans-serif;
  color: white;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  background: url('../../../assets/images/backgrounds/small-btn.png');
  padding-top: 3%;
  background-size: cover;
  background-position: center;
  aspect-ratio: 322 / 108;
  border: none;
  cursor: pointer; /* FH Pointer on hover */
}

.waiting {
  filter: sepia(1) hue-rotate(190deg) saturate(2);
  opacity: 0.6;
  cursor: pointer;
}

.accept {
  filter: hue-rotate(69deg);
  cursor: default;
}

.verify {
  filter: hue-rotate(180deg);
  cursor: pointer;
}
