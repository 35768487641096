@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.pageBg {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0028ff, #00abff);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.animatedImage {
    position: absolute;
    top: 0px;
    width: 100%;
    animation: moveUp 30s linear infinite;
    opacity: 0.033;
}

@keyframes moveUp {
    0% {
        top: 0px;
    }
    100% {
        top: calc(-400vw + 100vh);
    }
}

.pageContent {
    width: calc(100% - 40px);
    min-height: 300px;
    z-index: 2;
    position: relative;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.pageTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 42px;
    text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
    letter-spacing: 1px;
    margin: 10px 0 15px 0;
}

.referralPanel {
    width: 100%;
    aspect-ratio: 406/218;
    margin-bottom: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.buttonContainer {
    position: absolute;
    width: calc(100% - 30px);
    aspect-ratio: 406/80;
    bottom: 0;
    padding: 0 15px;
}

.refBtn {
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 22px;
    width: calc(50% - 10px);
    float: left;
    aspect-ratio: 181 / 50;
    border-radius: 6px;
    border: 2px solid rgba(239, 132, 35, 0.34);
    background-color: white;
    box-shadow: 0px 3px 10px 0px rgb(210 113 26);
    color: #ff8e30;
    padding-top: 2%;
}

.last {
    margin-left: 20px;
}


.referralPanel {
    width: 100%;
    aspect-ratio: 406/218;
    margin-bottom: 12px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.referralStats {
    width: 100%;
    min-height: 20px;
    overflow: hidden;
    position: relative;
    padding-bottom: 5px;
}

.referralStat {
    width: calc(50% - 28px);
    float: left;
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
    height: 60px;
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #002659;
    margin-bottom: 10px;
}

.infoBubble {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    background: white;
    color: #1e90ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: 'Luckiest Guy', cursive;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  
    &:hover {
        background-color: #f8f8f8;
    }
  
    &:active {
      transform: scale(0.95);
    }
  }

.statIcon {
    width: 42px;
    float: left;
    margin-right: 10px;
    margin-top: 9px;
}

.referralStatFirst {
    margin-right: 20px;
}

.referralStat p {
    float: left;
    width: calc(100% - 62px);
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 24px;
    line-height: 27px;
    margin: 0;
    margin-top: 4px;
}

.referralStat span {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 15px;
    opacity: 0.6;
}

.leaderboardButton {
    width: calc(100% - 18px) !important;
}

.leaderboardBtnText {
    width: calc(100% - 90px) !important;
}

.leaderboardIcon {
    width: 67px !important;
}

.divider {
    width: 100%;
    height: 3px;
    background-color: white;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
    opacity: 0.5;
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 100%;
}

.label {
    margin: 10px 0;
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    opacity: 0.7;
  }

.back_button {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.back_button img {
    height: 24px;
    width: auto;
}

