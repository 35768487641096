.popupTitle {
    color: white;
    font-family: 'Luckiest Guy', sans-serif;
    font-size: 28px;
    text-shadow: 0px 3px 11px rgba(0, 68, 158, 0.39);
    text-align: center;
    margin-bottom: 10px;
}

.popupImage {
    width: auto;
    height: 100px;
    margin: 0 auto;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.popupMessage {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    margin: 5px 0 15px 0;
    text-align: center;
    line-height: 1.6;
}

.benefitsList {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    color: #ffd800;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 15px;
    line-height: 1.8;
    text-shadow: 0px 2px 0px #8c5b0069;
    text-align: center;
}

.benefitsList li {
    margin-bottom: 8px;
}

.privacyNote {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    opacity: 0.8;
    text-align: center;
    margin: 15px 0;
    line-height: 1.4;
}

.popupButtons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.acceptButton {
    background: linear-gradient(0deg, #0167ff, #01a9ff);
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-weight: bold;
    padding: 12px 30px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-shadow: 0px 0px 3px #0000002b;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.acceptButton:hover {
    transform: scale(1.05);
    background: linear-gradient(0deg, #0056d2, #017adf);
}